<template>
  <div class="account-content">
    <div class="account-content__wrapper"
         :class="params.transparent
                 ? 'account-content__transparent'
                 : 'account-content__white'">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccountContent',
  props: {
    params: {
      type: Object,
      default: () => ({ transparent: false }),
    },
  },
};
</script>
